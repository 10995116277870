<template>
  <div id="public-statistik" data-app>
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="col-12 col-sm-6">
            <Populasi tipe="GP" :height="180"></Populasi>
          </div>
          <div class="col-12 col-sm-6">
            <Populasi tipe="PS" :height="180"></Populasi>
          </div>
          <div class="col-12 col-sm-6">
            <PopulasiProduksi tipe="GP" :height="180"></PopulasiProduksi>
          </div>
          <div class="col-12 col-sm-6">
            <PopulasiProduksi tipe="PS" :height="180"></PopulasiProduksi>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="row h-100">
          <div class="col-12">
            <SettingHe tipe="GP" :height="530"></SettingHe>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <SettingHe tipe="PS" :height="530"></SettingHe>
      </div>
      <div class="col-12 col-md-4">
        <Produksi tipe="GP" :height="530"></Produksi>
      </div>
      <div class="col-12 col-md-4">
        <Produksi tipe="PS" :height="530"></Produksi>
      </div>
      <div class="col-12 col-md-6">
        <DistribusiPerbulan tipe="GP" :height="250"></DistribusiPerbulan>
      </div>
      <div class="col-12 col-md-6">
        <DistribusiPerbulan tipe="PS" :height="250"></DistribusiPerbulan>
      </div>
      <div class="col-12">
        <DistribusiPerprovinsi></DistribusiPerprovinsi>
      </div>
    </div>

  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import Populasi from "@/view/pages/statistik/_public/_utama/_Populasi.vue"
import PopulasiProduksi from "@/view/pages/statistik/_public/_utama/_PopulasiProduksi.vue"
import SettingHe from "@/view/pages/statistik/_public/_utama/_SettingHe.vue"
import Produksi from "@/view/pages/statistik/_public/_utama/_Produksi.vue"
import DistribusiPerbulan from "@/view/pages/statistik/_public/_utama/_DistribusiPerbulan.vue"
import DistribusiPerprovinsi from "@/view/pages/statistik/_public/_utama/_DistribusiPerprovinsi.vue"
// import DistribusiIntEks from "@/view/pages/statistik/_public/_utama/_DistribusiIntEks.vue"

export default {
  name: "public-statistik",
  components: {
    Populasi,
    PopulasiProduksi,
    SettingHe,
    Produksi,
    DistribusiPerbulan,
    DistribusiPerprovinsi,
    // DistribusiIntEks,
  },
  data() {
    return {
      //
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Poultrynesia" }]);
  },
};
</script>