import ApiService from "@/core/services/api.service";

export default {
    // -----API DATA------
    getPopulasiExisting(credentials) {
        ApiService.setHeader();
        return ApiService.query("v2/public/statistik/populasi/existing", { params: credentials })
    },
    getPopulasiProduktif(credentials) {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/populasi/produktif", { params: credentials })
    },
    getSettingHe(credentials) {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/setting-he/perbulan", { params: credentials })
    },
    getProduksiDoc(credentials) {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/produksi/perbulan", { params: credentials })
    },
    getDistribusiPerbulan(credentials) {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/distribusi/perbulan", { params: credentials })
    },
    getDistribusiPerprovinsi(credentials) {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/distribusi/perprovinsi", { params: credentials })
    },
    getDistribusiIntEks(credentials) {
      ApiService.setHeader();
      return ApiService.query("v2/public/statistik/distribusi/int-eks", { params: credentials })
    },
}